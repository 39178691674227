// ACORE CAPITAL THEME
// EM/REM VALUES ASSUME 16PX BROWSER DEFAULT FONT SIZE

// CSS IMPORTS
// import "./typography.css" // (UNCOMMENT AND CONFIGURE IF HOSTING FONTS LOCALLY)

// THEME EXPORT
export default {
  breakpoints: ["40rem", "58rem", "70rem", "74rem"],
  sizes: {
    heroWidth: "72rem",
    containerWidth: "60rem", // 960px
    container: "56.25rem", // 900px
    contentWidth: "56.25rem", // 900px
    testContainer: "40rem",
    logo: "118px",
  },
  space: {
    defaultPadding: "1.875rem", // 30px, (container - content / 2)
    columnSpacing: "1.625rem", // 26+26 or 52px
    columnSpacingNarrow: "1.375rem", // 22+22 or 44px
  },
  colors: {
    // Base theme-ui definitions
    text: "#000",
    background: "#fff",
    primary: "#00529c", // ACORE CAPITAL blue
    lightBlue: "#009dd2",
    secondary: "#00ff00",
    accent: "#0000ff",
    muted: "#ccc",
    // Primary palette
    acoreBlack: "#000",
    acoreWhite: "#fff",
    // Temporary palette
    temporaryGray: "#444",
  },
  fonts: {
    body: "Roboto, Helvetica, Arial, sans-serif",
    heading: "Roboto, Helvetica, Arial, sans-serif",
  },
  fontSizes: [
    // Associated size in pixels assumes 16px browser default
    "0.5rem", // 8px
    "0.5625rem", // 9px
    "0.625rem", // 10px
    "0.75rem", // 12px
    "0.875rem", // 14px
    "1rem", // 16px, browser default for most users
    "1.125rem", // 18px
    "1.25rem", // 20px
    "1.375rem", // 22px
    "1.5rem", // 24px
    "1.75rem", // 28px
    "2rem", // 32px
    "2.25rem", // 36px
    "3rem", // 48px
    "3.5rem", // 56px
    "4rem", // 64px
    "4.5rem", // 72px
    "5rem", // 80px
    "6rem", // 96px
  ],
  fontWeights: {
    light: 300,
    normal: 400,
    medium: 500,
    bold: 700,
    black: 900,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.125,
  },
  images: {
    // Logo & Brand Promise defined with position attributes
    // of two child elements of a flex container. Move to header.js
    // file if this combination is not used elsewhere on the site.
    logo: {
      width: "logo",
      display: "block",
      "@media screen and (max-width: calc(25.875rem - 1px))": {
        width: "logoNarrow",
      },
    },
  },
  buttons: {
    primary: {
      fontFamily: "body",
      fontWeight: "bold",
      color: "#00529c",
      bg: "#eee",
      borderBottom: "1px solid #00529c",
      borderRadius: 0,
      paddingLeft: "10px",
      paddingRight: "10px",
      textDecoration: "none",
      "&:hover": {
        color: "white",
        bg: "#00529c",
        borderColor: "#00529c",
        cursor: "pointer",
      },
    },
    reverse: {
      fontFamily: "body",
      fontWeight: "bold",
      color: "acoreWhite",
      textTransform: "uppercase",
      bg: "acoreBlack",
      borderWidth: "1px",
      borderStyle: "solid",
      borderColor: "acoreBlack",
      borderRadius: 0,
      pt: "0.5625rem",
      pb: "0.625rem",
      px: "1.5rem",
      "&:hover": {
        color: "acoreBlack",
        bg: "acoreWhite",
        borderColor: "acoreBlack",
        cursor: "pointer",
      },
    },
    blueButton: {
      fontFamily: "body",
      fontWeight: "regular",
      color: "#fff !important",
      textDecoration: "none",
      bg: "#009dd2",
      borderBottom: "1px solid #0a4ea8",
      borderRadius: 0,
      py: "0.5rem",
      px: "1.5rem",
      "&:hover": {
        color: "white !important",
        bg: "#0a4ea8",
        borderColor: "black",
        cursor: "pointer",
      },
    },
    grayButton: {
      fontFamily: "body",
      fontWeight: "regular",
      color: "#4d4d4d !important",
      textDecoration: "none",
      bg: "#eee",
      borderBottom: "1px solid #0a4ea8",
      borderRadius: 0,
      py: "0.5rem",
      px: "1.5rem",
      "&:hover": {
        color: "white !important",
        bg: "#0a4ea8",
        borderColor: "black",
        cursor: "pointer",
      },
    },
    greyButton: {
      fontFamily: "body",
      fontWeight: "regular",
      color: "#4d4d4d !important",
      textDecoration: "none",
      bg: "#eee",
      borderBottom: "1px solid #0a4ea8",
      borderRadius: 0,
      py: "0.5rem",
      px: "1.5rem",
      "&:hover": {
        color: "white !important",
        bg: "#0a4ea8",
        borderColor: "black",
        cursor: "pointer",
      },
    },
    notDefined: {
      fontFamily: "body",
      fontWeight: "regular",
      color: "#fff !important",
      textDecoration: "none",
      bg: "crimson",
      borderBottom: "1px solid crimson",
      borderRadius: 0,
      py: "0.5rem",
      px: "1.5rem",
      "&:hover": {
        color: "white !important",
        bg: "black",
        borderColor: "black",
        cursor: "pointer",
      },
    },
  },
  badges: {
    primary: {
      fontFamily: "body",
      fontWeight: "bold",
      fontSize: 5,
      lineHeight: "1rem",
      textTransform: "uppercase",
      bg: "acoreBlack",
      color: "acoreWhite",
      py: "0.5rem",
      px: "1.25rem",
      borderRadius: 0,
    },
    reverse: {
      fontFamily: "body",
      fontWeight: "bold",
      fontSize: 5,
      lineHeight: "1rem",
      textTransform: "uppercase",
      bg: "acoreWhite",
      color: "acoreBlack",
      py: "0.5rem",
      px: "1.25rem",
      borderRadius: 0,
    },
  },
  layouts: {
    aTest: {
      container: "500px",
    },
  },
  // HTML ELEMENTS
  styles: {
    root: {
      fontFamily: "body",
      margin: 0,
      ".modal-open": {
        overflow: "auto !important",
      }
    },


    // p: {
    //   lineHeight: 1.75,
    //   fontSize: "17px",
    // },
    // h1: {
    //   fontFamily: "heading",
    //   fontSize: 12,
    //   fontWeight: "bold",
    //   color: "acoreBlack",
    //   lineHeight: 1.25,
    // },
    // h2: {
    //   fontFamily: "heading",
    //   fontSize: 12,
    //   fontWeight: "bold",
    //   color: "acoreBlack",
    //   lineHeight: 1.25,
    // },
    // h3: {
    //   fontFamily: "heading",
    //   fontSize: 10,
    //   fontWeight: "bold",
    //   color: "acoreBlack",
    // },
    // h4: {
    //   fontFamily: "heading",
    //   fontSize: 7,
    //   fontWeight: "bold",
    //   color: "acoreBlack",
    // },
    // h5: {
    //   fontFamily: "heading",
    //   fontSize: 6,
    //   fontWeight: "bold",
    //   color: "acoreBlack",
    // },
    // h6: {
    //   fontFamily: "heading",
    //   fontSize: 5,
    //   fontWeight: "bold",
    //   color: "acoreBlack",
    // },
    
    a: {
      color: "#00529c",
      textDecoration: "none",
      "&:hover": { color: "#009dd2", cursor: "pointer" },
    },
    ul: {
      margin: 0,
      padding: "0 1rem",
    },
    ol: {
      margin: 0,
      padding: "0 1rem",
    },
    sup: {
      fontSize: ".5em",
      verticalAlign: "top",
    },
    sub: {
      fontSize: ".5em",
      verticalAlign: "bottom",
    },
    ".contactPageColumn": {
      p: {
        flex: ["1 1 100%", null, "1 1 50%;"],
        fontWeight: "bold",
      },
      "p:nth-child(odd)": {
        // border: "1px solid crimson",
        textAlign: "left",
      },
      "p:nth-child(even)": {
        // border: "1px solid blue",
        textAlign: ["left", null, "right"],
      },
    },
    // blockquote: {
    //   fontSize: 12,
    //   fontWeight: "light",
    //   color: "acoreBlack",
    // },
  },
}
